.App {
  width: 330px;
  margin: 10px auto 0 auto;
}

.container {
  padding: 10px;
  border-radius: 12px;
  background-color: aliceblue;
  min-height: 400px;
}

.footer {
  margin: 0 auto;
  padding: 10px;
  font-size: 0.8em;
  line-height: 1.4em;
  color: black;
}

.footer p {
  margin: 0 auto 10px auto;
}

.footer a {
  color: black;
}

.appHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.appHeader .home {
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  font-size: 1.1em;
}

.appHeader .home img {
  margin-left: 5px;
  display: block;
  width: 30px;
  height: 30px;
}

.appHeader .home .text {
  padding: 0px 4px;
}

.appHeader .home .textIcon {
  font-size: 1.2em;
}

.appHeader a {
  color: black;
  text-decoration: none;
}

@media only screen and (max-width: 425px) {
  .App {
    width: auto;
    padding: 0 10px;
    margin: 0;
  }

  .innerContainer {
    max-width: 300px;
    margin: 0 auto;
  }

  .appHeader {
    background: lightseagreen;
  }

  .appHeader .home {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .footer {
    background: lightseagreen;
  }

  .footer p {
    padding-left: 0;
    padding-right: 0;
    max-width: 300px;
  }
}
