/* Switch.css */
.switch-container {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.switch {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 20px;
  background-color: #bdc3c7;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.switch.checked {
  background-color: lightseagreen;
}

.input {
  display: none;
}

.knob {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: transform 0.3s;
}

.switch.checked .knob {
  transform: translateX(20px);
}

.label {
  margin-left: 8px;
}
