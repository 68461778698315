.TripPageHeader {
  display: flex;
}

.TripPageHeader .routeLogo {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
}

.TripPageHeader .details {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TripPageHeader .details div {
  padding: 2px;
}

.TripPageHeader .details .big {
  font-size: 1em;
}

.TripPageHeader .details .small {
  color: gray;
  font-size: 0.9em;
}

.TripData {
  margin: 8px 4px;
  display: flex;
  align-items: center;
}

.TripData .dataKey {
  color: dimgray;
  font-size: 0.9em;
  margin-right: 4px;
}

.TripData .value {
  border-radius: 5px;
  border: 1px;
  padding: 2px;
}

.TripData .value.code {
  font-family: monospace;
  border: 1px solid lightgray;
}

.TripPage .ServiceMap {
  margin: 15px 0;
}
