.ListOfStopsElement {
  border-radius: 12px;
  padding: 8px 5px;
  display: flex;
  align-items: center;
}

.ListOfStopsElement .ListOfRouteLogos > div {
  width: 22px;
  height: 22px;
  margin: 0 2px;
}

.ListOfStopsElement .name {
  margin-left: 3px;
  font-size: 0.9em;
}
