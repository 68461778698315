.ListOfRouteLogos {
  display: flex;
  justify-content: center;
}


.ListOfRouteLogos .statusCircle {
  transition: background-color 0.5s;
  width: 15%;
  height: 0;
  padding: 15% 0 0 0;
  position: absolute;
  border-radius: 100%;
  right: 0;
  top: 0;
  background-color: dimgray;
}

.ListOfRouteLogos .logoWrapper {
  position: relative;
}


.ListOfRouteLogos .Orange {
  background-color: orange;
  transition: background-color 500ms;
}

.ListOfRouteLogos .Red {
  background-color: red;
  transition: background-color 500ms;
}

.ListOfRouteLogos .Green {
  background-color: #6f6;
  transition: background-color 500ms;
}

.ListOfRouteLogos .Gray {
  background-color: dimgray;
  transition: background-color 500ms;
}