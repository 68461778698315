.StopPage .mainRoutes .ListOfRouteLogos > div {
  width: 35px;
  height: 35px;
  margin: 0 2px;
}

.StopPage .noTrainsScheduled {
  text-align: center;
  color: dimgray;
  font-style: italic;
  margin: 15px 0 20px 0;
}

.StopPage .LastUpdated {
  color: dimgray;
  font-size: 0.8em;
  text-align: center;
}

.StopPage .Stale {
  background-color: #f66;
}

.StopPage .Fresh {
  background-color: #6f6;
}

.StopPage .TripStopTime {
  padding: 8px 5px;
  display: flex;
  align-items: center;
}

.StopPage .TripStopTime .time {
  width: 30px;
  text-align: right;
}

.StopPage .TripStopTime .route {
  width: 22px;
  height: 22px;
  margin: 0 5px;
}

.StopPage .TripStopTime .lastStop {
  margin-left: 3px;
  font-size: 0.9em;
}

.StopPage .TripStopTime .terminating {
  color: dimgray;
}

.StopPage .scheduledTripWarning {
  font-size: 0.9em;
  margin: 10px 0;
}

.MoreTrips {
  color: #444;
  padding: 10px;

  font-size: 0.9em;
  cursor: pointer;
  text-align: center;
}

.MoreTrips:hover {
  color: black;
}
