.ServiceMap .ListElement {
  display: flex;
  cursor: pointer;
}

.ServiceMap a {
  color: black;
  text-decoration: none;
}

.ServiceMap .time {
  flex-shrink: 0;
  font-size: 0.9em;
  text-align: right;
}

.ServiceMap.withTimes .time {
  width: 15%;
  padding: 11px 4px 8px 4px;
}

.ServiceMap.noTimes .time {
  width: 0;
  padding: 8px 4px;
}

.ServiceMap .map {
  flex-shrink: 0;
  width: 20px;
  position: relative;
}

.ServiceMap .ListElement .line {
  width: 4px;
  left: 8px;
  position: absolute;
}

.ServiceMap .ListElement.regular .line {
  top: 0;
  bottom: 0;
}

.ServiceMap .ListElement.startingTerminus .line {
  top: 15px;
  bottom: 0;
}

.ServiceMap .ListElement.startingTerminus.inActive .line {
  top: 12px;
  border-radius: 2px 2px 0 0;
}

.ServiceMap .ListElement.endingTerminus .line {
  top: 0;
  height: 15px;
}

.ServiceMap .ListElement.endingTerminus.inActive .line {
  height: 20px;
  border-radius: 0 0 2px 2px;
}

.ServiceMap .ListElement.intermediateEnRoute .name {
  font-style: italic;
  padding-top: 30px;
  padding-bottom: 30px;
}

.ServiceMap .arrow {
  left: 2px;
  top: 32px;
  border: 10px solid transparent;
  border-width: 16px 8px;
  width: 0px;
  height: 0px;
  position: absolute;
}

.ServiceMap .point {
  left: 5px;
  top: 14px;
  background: white;
  width: 6px;
  height: 6px;
  border: 2px solid black;
  position: absolute;
}

.ServiceMap .ListElement.inActive .point {
  display: none;
}

.ServiceMap .ListElement.regular .point {
  border-radius: 50%;
}

.ServiceMap .name {
  font-size: 0.9em;
  padding: 10px 6px;
  text-align: left;
}

.ServiceMap .ListElement.inActive .name {
  padding-top: 8px;
  padding-bottom: 8px;
  color: dimgray;
}

.ServiceMap .ListElement.past {
  color: dimgrey;
}
