.List > *:nth-child(odd) .ListElement,
.List > .ListElement:nth-child(odd) {
  background: #e1f4f7;
}

.List .ListElement:hover {
  background-color: lightseagreen;
}

.List > a {
  color: black;
  text-decoration: none;
}

.List .ListElement {
  border-radius: 12px;
}
